import * as DropdownPrimitives from "@radix-ui/react-dropdown-menu";
import "./faDropdownMenuContent.scss";
import clsx from "clsx";

interface IFaDropdownMenuContentProps extends DropdownPrimitives.MenuContentProps { }

export const FaDropdownMenuContent = (props: IFaDropdownMenuContentProps) => {
    const { children, sideOffset, collisionPadding, className, ...contentProps } = props;

    return (
        <DropdownPrimitives.Portal>
            <DropdownPrimitives.Content
                className={clsx("fa-dropdown-menu-content", className)}
                sideOffset={sideOffset ?? 8}
                collisionPadding={collisionPadding ?? 16}
                {...contentProps}>
                {children}
            </DropdownPrimitives.Content>
        </DropdownPrimitives.Portal>
    );
};
