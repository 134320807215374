import * as DropdownPrimitives from "@radix-ui/react-dropdown-menu";
import clsx from "clsx";
import "./faDropdownMenuTrigger.scss";

interface IFaDropdownMenuTriggerProps extends Omit<DropdownPrimitives.DropdownMenuTriggerProps, "asChild"> {
    /**
     * Decides, if the trigger should take over it's child or render as a separate <button> element.
     * If true the child element gets taken.
     * @default true
     */
    asChild?: boolean;
}

export const FaDropdownMenuTrigger = (props: IFaDropdownMenuTriggerProps) => {
    const { children, className, asChild = true, ...triggerProps } = props;
    return (
        <DropdownPrimitives.Trigger
            className={clsx("fa-dropdown-menu-trigger", className)}
            {...triggerProps}
            asChild={asChild}>
            {children}
        </DropdownPrimitives.Trigger>
    );
};
