import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as DropdownPrimitives from "@radix-ui/react-dropdown-menu";
import clsx from "clsx";
import React from "react";
import { DropdownMenuItemColor, getDropdownMenuItemClasses } from "../FaDropdownMenuItem/FaDropdownMenuItem";
import { FaDropdownMenuItemIndicator } from "../FaDropdownMenuItemIndicator/FaDropdownMenuItemIndicator";
import "./faDropdownMenuRadioItem.scss";

interface IFaDropdownMenuRadioItemOwnProps extends DropdownPrimitives.MenuRadioItemProps {
    icon?: IconProp;
    /**
     * @default "light"
     */
    color?: DropdownMenuItemColor;
    /**
     * An optional tooltip. Set to `undefined` to not show a tooltip
     * @default undefined
     */
    tooltip?: React.ReactNode;
}

interface IFaDropdownMenuRadioItemProps
    extends IFaDropdownMenuRadioItemOwnProps,
    Omit<DropdownPrimitives.MenuRadioItemProps, keyof IFaDropdownMenuRadioItemOwnProps> { }

export function FaDropdownMenuRadioItem(props: IFaDropdownMenuRadioItemProps) {
    const { icon, color = "light", children, className, ...labelProps } = props;

    const iconComponent = icon ? (
        <span className={"fa-dropdown-menu-item__icon"}>
            <FontAwesomeIcon icon={icon} />
        </span>
    ) : null;

    return (
        <DropdownPrimitives.RadioItem
            className={getDropdownMenuItemClasses({
                hasIcon: !!icon,
                color,
                className: clsx("fa-dropdown-menu-radio-item", className),
            })}
            {...labelProps}>
            <div className="fa-dropdown-menu-radio-item__label-slot">
                {iconComponent}
                {children}
            </div>
            <span className="fa-dropdown-menu-radio-item__indicator-slot">
                <FaDropdownMenuItemIndicator className="fa-dropdown-menu-radio-item__indicator" />
            </span>
        </DropdownPrimitives.RadioItem>
    );
}
