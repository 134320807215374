import * as DropdownPrimitives from "@radix-ui/react-dropdown-menu";

interface IFaDropdownMenuItemIndicatorProps extends DropdownPrimitives.MenuItemIndicatorProps { }

export function FaDropdownMenuItemIndicator(props: IFaDropdownMenuItemIndicatorProps) {
    const { children, ...labelProps } = props;
    return (
        <DropdownPrimitives.ItemIndicator
            {...labelProps}>
            {children}
        </DropdownPrimitives.ItemIndicator>
    );
}
