import { FaDropdownMenuRoot } from "./FaDropdownMenuRoot/FaDropdownMenuRoot";
import { FaDropdownMenuTrigger } from "./FaDropdownMenuTrigger/FaDropdownMenuTrigger";
import { FaDropdownMenuContent } from "./FaDropdownMenuContent/FaDropdownMenuContent";
import { FaDropdownMenuItem } from "./FaDropdownMenuItem/FaDropdownMenuItem";
import { FaDropdownMenuLabel } from "./FaDropdownMenuLabel/FaDropdownMenuLabel";
import { FaDropdownMenuDivider } from "./FaDropdownMenuDivider/FaDropdownMenuDivider";
import { FaDropdownMenuRadioGroup } from "./FaDropdownMenuRadioGroup/FaDropdownMenuRadioGroup";
import { FaDropdownMenuRadioItem } from "./FaDropdownMenuRadioItem/FaDropdownMenuRadioItem";
import { FaDropdownMenuItemIndicator } from "./FaDropdownMenuItemIndicator/FaDropdownMenuItemIndicator";

export const DropdownMenu = FaDropdownMenuRoot;
export const Root = FaDropdownMenuRoot;

export const Trigger = FaDropdownMenuTrigger;
export const Content = FaDropdownMenuContent;
export const Item = FaDropdownMenuItem;
export const Label = FaDropdownMenuLabel;
export const Divider = FaDropdownMenuDivider;

export const RadioGroup = FaDropdownMenuRadioGroup;
export const RadioItem = FaDropdownMenuRadioItem;
export const ItemIndicator = FaDropdownMenuItemIndicator;
