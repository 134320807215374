import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as DropdownPrimitives from "@radix-ui/react-dropdown-menu";
import clsx from "clsx";
import React, { forwardRef } from "react";
import { Link, LinkProps } from "react-router-dom";
import "./faDropdownMenuItem.scss";
import * as Tooltip from "@radix-ui/react-tooltip";
import { computed } from "../../../util/functions";

export type DropdownMenuItemColor = "light" | "primary" | "danger";

interface GetDropdownMenuItemClassesParams {
    hasIcon: boolean;
    color: DropdownMenuItemColor;
    className?: string;
}

export function getDropdownMenuItemClasses(params: GetDropdownMenuItemClassesParams): string {
    return clsx(
        "fa-dropdown-menu-item",
        {
            "fa-dropdown-menu-item--color-light": params.color === "light",
            "fa-dropdown-menu-item--color-primary": params.color === "primary",
            "fa-dropdown-menu-item--color-danger": params.color === "danger",
            "fa-dropdown-menu-item--has-icon": params.hasIcon,
        },
        params.className
    );
}

interface IFaDropdownMenuItemOwnProps {
    imageIconPath?: string;
    icon?: IconProp;
    /**
     * @default "leading"
     */
    iconPosition?: "leading" | "trailing";
    routerLink?: LinkProps["to"];
    /**
     * @default "light"
     */
    color?: DropdownMenuItemColor;
    /**
     * An optional tooltip. Set to `undefined` to not show a tooltip
     * @default undefined
     */
    tooltip?: React.ReactNode;
}

interface IFaDropdownMenuItemProps
    extends IFaDropdownMenuItemOwnProps,
    Omit<DropdownPrimitives.MenuItemProps, keyof IFaDropdownMenuItemOwnProps> { }

export const FaDropdownMenuItem = forwardRef<HTMLDivElement, IFaDropdownMenuItemProps>((props, forwardedRef) => {
    const {
        icon,
        iconPosition = "leading",
        onSelect,
        routerLink,
        disabled = false,
        color = "light",
        tooltip,
        className,
        imageIconPath,
        ...restProps
    } = props;

    const iconComponent = computed(() => {
        if (icon) {
            return (
                <span
                    className={clsx("fa-dropdown-menu-item__icon", {
                        "fa-dropdown-menu-item__icon--trailing": iconPosition === "trailing",
                    })}>
                    <FontAwesomeIcon icon={icon} />
                </span>
            );
        }
        if (imageIconPath) {
            return <img src={imageIconPath} className="fa-dropdown-menu-item__image-icon" />;
        }
        return null;
    });

    const child = (
        <>
            {iconPosition === "leading" && iconComponent}
            {restProps.children}
            {iconPosition === "trailing" && iconComponent}
        </>
    );

    const renderChild = (): React.ReactNode => {
        if (routerLink) {
            return <Link to={routerLink}>{child}</Link>;
        }

        return <span>{child}</span>;
    };

    const primitive = (
        <DropdownPrimitives.Item
            {...restProps}
            ref={forwardedRef}
            asChild
            onSelect={onSelect}
            disabled={disabled}
            className={getDropdownMenuItemClasses({ color, className, hasIcon: !!iconComponent })}>
            {renderChild()}
        </DropdownPrimitives.Item>
    );

    const collisionPadding = 16;

    if (tooltip) {
        return (
            <Tooltip.Root>
                <Tooltip.Trigger asChild>{primitive}</Tooltip.Trigger>

                <Tooltip.Portal>
                    <Tooltip.Content
                        collisionPadding={collisionPadding}
                        sideOffset={8}
                        className="fa-dropdown-menu-item__tooltip">
                        {tooltip}
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip.Root>
        );
    }

    return primitive;
});
