import * as DropdownPrimitives from "@radix-ui/react-dropdown-menu";
import React, { useState } from "react";
import { FaPopperOverlay } from "../../FaPopperOverlay/FaPopperOverlay";

interface IFaDropdownMenuRootProps extends DropdownPrimitives.DropdownMenuProps {
    onOverlayClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export function FaDropdownMenuRoot(props: IFaDropdownMenuRootProps) {
    const { onOverlayClick, onOpenChange, open: controlledOpen, ...rootProps } = props;
    const [open, setOpen] = useState(false);
    const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setOpen(false);
        onOverlayClick?.(event);
        event.stopPropagation();
    };

    return (
        <DropdownPrimitives.Root
            {...rootProps}
            open={controlledOpen ?? open}
            onOpenChange={value => {
                setOpen(value);
                onOpenChange?.(value);
            }}>
            <FaPopperOverlay open={controlledOpen ?? open} onClick={handleOverlayClick} />
            {props.children}
        </DropdownPrimitives.Root>
    );
}
