import * as DropdownPrimitives from "@radix-ui/react-dropdown-menu";
import clsx from "clsx";
import "./faDropdownMenuLabel.scss";

interface IFaDropdownMenuLabelProps extends DropdownPrimitives.MenuLabelProps { }

export function FaDropdownMenuLabel(props: IFaDropdownMenuLabelProps) {
    const { children, className, ...labelProps } = props;
    return (
        <DropdownPrimitives.Label className={clsx("fa-dropdown-menu-label", className)} {...labelProps}>
            {children}
        </DropdownPrimitives.Label>
    );
}
