import * as PortalPrimitives from "@radix-ui/react-portal";
import clsx from "clsx";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import "./faPopperOverlay.scss";

interface IFaPopperOverlayProps {
    open?: boolean;
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const fadeDuration = 500;

export const FaPopperOverlay = forwardRef<HTMLDivElement, IFaPopperOverlayProps>((props, forwardedRef) => {
    const [mounted, setMounted] = useState(props.open);
    const unmountTimerRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
        clearTimeout(unmountTimerRef.current);
        if (props.open) {
            setMounted(props.open);
        } else {
            unmountTimerRef.current = setTimeout(() => {
                setMounted(false);
            }, fadeDuration);
        }
    }, [props.open]);

    // the first condition is required because otherwise the content's portal gets added to the body
    // before this one does, resulting in the fade-plane covering the content
    return props.open || mounted ? (
        <PortalPrimitives.Root>
            <PortalPrimitives.Portal
                ref={forwardedRef}
                onClick={props.onClick}
                style={{ "--fa-popper-fade-duration": `${fadeDuration}ms` } as React.CSSProperties}
                className={clsx("fa-popper-overlay", {
                    "fa-popper-overlay--visible": props.open,
                })}
            />
        </PortalPrimitives.Root>
    ) : null;
});
